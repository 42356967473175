import {GlobalOptions} from './GlobalOptions'

export const BorderOptions = [
    {
        label: 'None',
        value: 'none'
    },
    {
        label: 'Hidden',
        value: 'hidden'
    },
    {
        label: 'Dotted',
        value: 'dotted'
    },
    {
        label: 'Dashed',
        value: 'dashed'
    },
    {
        label: 'Solid',
        value: 'solid'
    },
    {
        label: 'Double',
        value: 'double'
    },
    {
        label: 'Groove',
        value: 'groove'
    },
    {
        label: 'Ridge',
        value: 'ridge'
    },
    {
        label: 'Inset',
        value: 'inset'
    },
    {
        label: 'Outset',
        value: 'outset'
    },
    ...GlobalOptions
]