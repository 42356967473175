import SectionImage from '../shared/SectionImage'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import constructAssetUrl from '../../editor/shared/constructAssetUrl'
import {LucidBlocksControls} from '../shared/LucidInlineGroups'

// TODO: Use actual `cms`

// Base item for all the other item types to pull from
const baseItem = {
  label: 'Item',
  defaultItem: {
    title: 'First Line',
    subtitle: 'Second Line',
  },
  itemProps: (item) => {
    if (!item.title && !item.subtitle) {
      return
    }

    return {label: item.title || item.subtitle}
  },
  // TODO: Create a set of default fields
  fields: [
    {
      name: 'title',
      label: 'First Line',
      component: 'html',
    },
    {
      name: 'subtitle',
      label: 'Second Line',
      component: 'html',
    },
  ],
}

// Templates to use in the parent's block config and the InlineBlocks component
const itemTemplates = {
  twocolumnnarrowwidelistimages: {
    ...baseItem,
    label: 'Item',
    defaultItem: {
      ...baseItem.defaultItem,
      image: {
        src: 'https://tools--dev.cms.eiidev.com/placeholder/100/100',
        alt: '',
      },
      href: '',
      description:
        '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>',
    },
    fields: [
      ...baseItem.fields,
      {
        name: 'image',
        label: 'Image',
        component: 'group',
        fields: [
          {
            name: 'src',
            label: 'Image',
            component: 'image',
            description: 'Image description',
            clearable: true,
            parse: (media) => {
              if (!media) {
                return
              }
              return constructAssetUrl(media.id)
            },
            previewSrc: (src) => {
              return src
            },
          },
          {
            name: 'alt',
            label: 'Image Alt',
            component: 'text',
          },
        ],
      },
      {
        name: 'description',
        label: 'Description',
        component: 'html',
      },
      {
        name: 'href',
        label: 'Link',
        component: 'text',
      },
    ],
  },
}

export const TWO_COLUMN_NARROW_WIDE_LIST_IMAGES_ITEM_BLOCKS = {
  twocolumnnarrowwidelistimages: {
    Component({_name, index, data, cms}) {
      return (
        <>
          <div className="item">
            <ItemWrapper
              index={index}
              cms={cms}
            >
              {data.href ? (
                <a href={data.href}>
                  <div className="row collapse">
                    <div className="column shrink column-image">
                      <SectionImage
                        name="image"
                        src={data.image?.src}
                        mobile={data.image?.mobile}
                        alt={data.image?.alt}
                        width={data.image?.width}
                        height={data.image?.height}
                        priority={data.image?.priority}
                        sizes={data.image?.sizes}
                        cms={cms}
                      />
                    </div>
                    <div className="column column-content">
                      <h3>
                        <span className="first-line heading-3">
                          <RichtextWithVariables
                            name="title"
                            richtext={data.title}
                            cms={cms}
                          />
                        </span>
                        <span className="second-line heading-4">
                          <RichtextWithVariables
                            name="subtitle"
                            richtext={data.subtitle}
                            cms={cms}
                          />
                        </span>
                      </h3>
                      <div className="content paragraph">
                        <RichtextWithVariables
                          name="description"
                          richtext={data.description}
                          cms={cms}
                        />
                      </div>
                    </div>
                  </div>
                </a>
              ) : (
                <div className="row collapse">
                  <div className="column shrink column-image">
                    <SectionImage
                      name="image"
                      src={data.image?.src}
                      mobile={data.image?.mobile}
                      alt={data.image?.alt}
                      width={data.image?.width}
                      height={data.image?.height}
                      priority={data.image?.priority}
                      sizes={data.image?.sizes}
                      cms={cms}
                    />
                  </div>
                  <div className="column column-content">
                    <h3>
                      <span className="first-line paragraph">
                        <RichtextWithVariables
                          name="title"
                          richtext={data.title}
                          cms={cms}
                        />
                      </span>
                      <span className="second-line paragraph">
                        <RichtextWithVariables
                          name="subtitle"
                          richtext={data.subtitle}
                          cms={cms}
                        />
                      </span>
                    </h3>
                    <div className="content paragraph">
                      <RichtextWithVariables
                        name="description"
                        richtext={data.description}
                        cms={cms}
                      />
                    </div>
                  </div>
                </div>
              )}
            </ItemWrapper>
          </div>
        </>
      )
    },
    template: itemTemplates.twocolumnnarrowwidelistimages,
  },
}

function ItemWrapper(props) {
  return (
    <LucidBlocksControls
      index={props.index}
      cms={props.cms}
      focusRing={{offset: 16, borderRadius: 4}}
      insetControls={false}
    >
      {props.children}
    </LucidBlocksControls>
  )
}
