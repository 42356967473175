import type {BlockContents, Contents, PageSection} from '@/components/shared/types'
import {unmarshalItems} from '@/components/editor/shared/marshalItems'
import {
  EventUpdateAcceptedFieldContent,
  FieldConfig,
  fieldDataPoint,
  FormConfig,
  initialValueType,
  Listeners,
  ListenerTarget
} from '@/components/shared/externalTypes'
import type {FormOptions} from '@einsteinindustries/tinacms'
import {ADD_CONTENT, UPDATE_CONTENT} from '@/graphql/mutations'
import {CONTENT_VARIABLE_COPY_FIELD_NAME} from './shared/helpers'
import {lucidDataFetcherV2} from '@/graphql/fetchers'
import {mutate as globalMutate} from 'swr'
import {SITE_QUERY} from '@/graphql/queries'

export const FORM_MANAGER_PREFIX = 'lucid-form'
export const FORM_MANAGER_DELIMITER = '>'
export const INITIAL_DATA_META_GUARD = '_ref'

//TODO: Update the callers of this function to handle undefined values
export function extractFormManagerContents(contents: Contents[]): BlockContents {
  const blockContents: BlockContents = {}
  const formIds = []
  for (const piece of contents) {
    // strip first lucid form directive
    let strippedName = piece.name.slice(piece.name.indexOf(FORM_MANAGER_DELIMITER) + 1)
    if (strippedName === FORM_MANAGER_PREFIX) {
      // We might want to do something with the form id
      const form = strippedName.substring(0, strippedName.indexOf(FORM_MANAGER_DELIMITER))
      if (form !== '' && formIds.indexOf(form) < 0) {
        formIds.push(form)
      }
    } else {
      strippedName = piece.name
    }
    blockContents[strippedName] = piece.value
  }
  const inflatedContents = unmarshalItems([blockContents], FORM_MANAGER_DELIMITER)
  if (inflatedContents.length === 0) return {}
  const mergedContents: BlockContents = {}
  for (const [formId, inflatedContent] of Object.entries(inflatedContents[0])) {
    if (formIds.indexOf(formId) > -1 && typeof inflatedContent === 'object') {
      Object.assign(mergedContents, inflatedContent)
    } else {
      mergedContents[formId] = inflatedContent
    }
  }
  return mergedContents
}

export function initialValuesFromContent(
  formConfig: FormConfig & {content: FieldConfig | string}, contents: Contents[], field = '', formId?: string
): any {
  const configFormID = formId ?? formConfig.id

  if (typeof (formConfig.content) === 'string' && formConfig.content === 'blocks') {
    //Blocks do not use FormManager format, so we can't process them the way we process other FormManager content below
    //not sure why, but this is temporary until we phase out FormManager from siteConfig and pageConfig forms
    const key = `${FORM_MANAGER_PREFIX}${FORM_MANAGER_DELIMITER}${configFormID}${field}`
    const blocks = []
    const records = contents.filter(c => c.name.startsWith(key))

    for (const record of records) {
      const [indexString, fieldName] = record.name.split(FORM_MANAGER_DELIMITER).slice(-2)
      const index = parseInt(indexString)
      const data : {[key: string]: initialValueType} = blocks[index] ?? {}
      data[fieldName] = record.value
      data[`${fieldName}${INITIAL_DATA_META_GUARD}`] = `${record.id}::${record.value}`
      blocks[index] = data
    }
    return blocks
  }
  if (typeof formConfig.content === 'object') {
    const initialValues: initialValueType = {}
    for (const [label, fieldConfig] of Object.entries(formConfig.content ?? formConfig)) {
      const nextItem = `${field}${FORM_MANAGER_DELIMITER}${label}`
      const key = `${FORM_MANAGER_PREFIX}${FORM_MANAGER_DELIMITER}${configFormID}${nextItem}`
      const content = initialValuesFromContent(fieldConfig as FormConfig, contents, nextItem, configFormID)
      if (typeof content === 'string') {
        const [val, id] = content.split('::')
        initialValues[key] = val
        initialValues[`${key}${INITIAL_DATA_META_GUARD}`] = `${id}::${val}`
      } else {
        initialValues[key] = content
      }
    }
    return initialValues
  }
  const targetFieldName = `${FORM_MANAGER_PREFIX}${FORM_MANAGER_DELIMITER}${formId}${field}`
  const tip = contents.find(c => c.name === targetFieldName)
  return tip ? `${tip.value}::${tip.id}` : null
}

export function extractFormManagerValues(values: FormOptions<any>['initialValues'], recombineIdField = false) {
  const processedValues: fieldDataPoint = {}
  if (typeof values === 'object' && values !== null) {
    for (const [key, value] of Object.entries(values as object).sort((a, b) => a[0] > b[0] ? 1 : -1)) {
      const keyModified = key.substring(key.lastIndexOf(FORM_MANAGER_DELIMITER) + 1, key.length)
      if (recombineIdField && keyModified.endsWith(INITIAL_DATA_META_GUARD)) {
        const origKey = keyModified.substring(0, keyModified.length - 3)
        if (origKey in processedValues) {
          /**
           * NOTE:
           *  At this point, value is actually the id of this content piece
           */
          processedValues[origKey] = processedValues[origKey] + `::${value}`
        }
      } else {
        processedValues[keyModified as keyof initialValueType] = extractFormManagerValues(value, recombineIdField) ?? null
      }
    }
  } else {
    return values === '' ? null : values
  }
  return processedValues
}

export function extractSectionStyleContent(
  pageSections: PageSection[],
  headerSections: PageSection[],
  footerSections: PageSection[]
): { [id: string]: Contents[] } {

  const extractedContent: { [id: string]: Contents[] } = {}
  const allSections = pageSections.concat(headerSections).concat(footerSections)
  for (const pageSection of allSections) {
    const name = pageSection.section.name.toLocaleLowerCase()
    const pageSectionContent = []
    for (const sectionContent of pageSection.contents) {
      const accessor = `${FORM_MANAGER_PREFIX}${FORM_MANAGER_DELIMITER}${name}::ST${pageSection.id}`
      if (sectionContent.name.indexOf(accessor) === 0) {
        pageSectionContent.push({...sectionContent, name: sectionContent.name})
      }
    }
    extractedContent[pageSection.id] = pageSectionContent
  }

  return extractedContent
}

export async function saveChangedFormManager(field: string[], value: fieldDataPoint, listenerConfig: Listeners & Required<ListenerTarget>, id?: string) {
  if (listenerConfig.preventContentTableUpsert) return
  if (typeof value === 'object' && value !== null) {
    const processedIdFields: string[] = []
    const flayedData = Object.entries(value as object).sort((a, b) => a[0] > b[0] ? 1 : -1)
    for (const [label, fieldData] of flayedData) {
      if (processedIdFields.includes(label)) continue
      let existingId: string | undefined = undefined
      const existingFieldsData = flayedData.find(q => q[0] === `${label}${INITIAL_DATA_META_GUARD}`)
      if (existingFieldsData) {
        processedIdFields.push(existingFieldsData[0])
        existingId = existingFieldsData[1]
      }
      await saveChangedFormManager([...field, label], fieldData, listenerConfig, existingId)
    }
  } else {
    const fieldName = field.join(FORM_MANAGER_DELIMITER)
    await upsertContentTableRow(
      listenerConfig,
      fieldName.substring(fieldName.lastIndexOf(FORM_MANAGER_PREFIX)),
      value ? `${value}` : '',
      id
    )
  }
}

async function upsertContentTableRow({target}: Listeners & Required<ListenerTarget>, field: string, value: string, orig?: string) {
  // if field ends in 'copyvar' then it is a copy variable button and should not be stored in the database
  if (field.endsWith(CONTENT_VARIABLE_COPY_FIELD_NAME)) return
  let targetType = 'PAGE'
  if (target.site_build_id !== undefined) {
    targetType = 'SITE_BUILD'
  }
  if (target.site_id !== undefined) {
    targetType = 'SITE'
  }
  const existingData = typeof orig === 'string' ? orig.split('::') : null
  let query = null
  if (!existingData && value && value !== '') {
    query = [ADD_CONTENT, {
      newContent: {
        ...target,
        name: field,
        value,
        scope: targetType
      }
    }]
  } else if (existingData && existingData[1] !== value) {
    query = [UPDATE_CONTENT, {
      id: existingData[0],
      updateContentData: {
        value
      }
    }]
  } else return
  const {data} = await lucidDataFetcherV2<Contents>(query[0], query[1])
  if (!(data?.addContent?.id ?? data?.updateContent?.id)) {
    throw new Error('Failed to update/create content')
  }
}

export async function handleFormManagerChange ({values}: { values: any }, config: FormConfig) {
  if (typeof config.listeners?.onChange !== 'undefined') {
    config.listeners.onChange(extractFormManagerValues(values) as EventUpdateAcceptedFieldContent)
  }
  if (config.listeners?.contentTableUpsertOn && ['all', 'change'].includes(config.listeners?.contentTableUpsertOn)) {
    await saveChangedFormManager([FORM_MANAGER_PREFIX], values, config.listeners)
  }
}

export async function  handleFormManagerSubmit (values: any, config: FormConfig) {
  if (typeof config.listeners?.onSubmit !== 'undefined') {
    config.listeners.onSubmit(extractFormManagerValues(values) as EventUpdateAcceptedFieldContent)
  }
  if (!config.listeners?.contentTableUpsertOn || ['all', 'submit'].includes(config.listeners?.contentTableUpsertOn)) {
    await saveChangedFormManager([FORM_MANAGER_PREFIX], values, config.listeners)
    /**
     * This is a workaround for now so that we can update site config form
     * and prevent previous request from being resent
     */
    if (config.id === 'config') {
      await globalMutate([SITE_QUERY, {id: config.listeners.target.site_id}])
    }
  }
}
