import Sections from '@/components/sections'
import {convertTargetToKey} from '@/components/shared/StyleCore'
import {Defaults} from '@/components/sections/shared/stylecore/sets/Defaults'

export const GLOBAL_NAME = 'global'

export default function createInitialStyleCoreElementMap() {
    const styleCoreMap = new Map()
    const styleCorePresetMap = new Map()
    const presets = []
    for (const [, section] of Object.entries(Sections)) {
        if (typeof section.StyleCore !== 'undefined') {
            section.StyleCore.config = {
                general: {
                    content: {
                        ...Defaults.createFields(),
                    }
                },
                 ...section.StyleCore.config,
            }
            section.StyleCore.css += Defaults.createCSS('general.') + '\n' + section.StyleCore.css
            styleCoreMap.set(convertTargetToKey(section.StyleCore.target), section.StyleCore)
            styleCorePresetMap.set(convertTargetToKey(section.StyleCore.target), [])
            if (typeof section.presets !== 'undefined') {
                presets.push(...(section.presets.map(preset => {
                    if (typeof preset?.allowedSections === 'undefined' || !preset?.allowedSections.find(allowedSection => allowedSection === section.StyleCore.target.componentName)) {
                        return {
                            ...preset,
                            allowedSections: [section.StyleCore.target.componentName, ...(preset.allowedSections || [])],
                        }
                    }
                    return preset
                })))
            }
        }
    }
    // Add global preset
    styleCoreMap.set(convertTargetToKey({
        componentName: GLOBAL_NAME,
    }), {
        target: {
            componentName: GLOBAL_NAME,
        },
        config: {
            general: {
                content: {
                    ...Defaults.createFields(),
                }
            },
        },
        css: Defaults.createCSS('general.'),
    })

    styleCorePresetMap.set(convertTargetToKey({
        componentName: GLOBAL_NAME,
    }), [])

    for (const preset of presets) {
        if (preset.allowedSections.includes('all')) {
            for (const [key,] of styleCoreMap.entries()) {
                styleCorePresetMap.get(key).push(preset)
            }
        } else {
            for (const section of preset.allowedSections) {
                styleCorePresetMap.get(convertTargetToKey({
                    componentName: section,
                })).push(preset)
            }
        }
    }
    return {
        styleCoreMap,
        styleCorePresetMap,
    }
}
