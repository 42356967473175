import SectionImage from '../shared/SectionImage'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionText from '../shared/SectionText'
import constructAssetUrl from '../../editor/shared/constructAssetUrl'
import {LucidBlocksControls} from '../shared/LucidInlineGroups'

// TODO: Use actual `cms`

const baseItem = {
  label: 'Item',
  defaultItem: {
    title: 'Title',
    subtitle: 'Subtitle',
  },
  itemProps: (item) => {
    if (!item.title && !item.subtitle) {
      return
    }

    return {label: item.title || item.subtitle}
  },
  // TODO: Create a set of default fields
  fields: [
    {
      name: 'title',
      label: 'Title',
      component: 'text',
    },
    {
      name: 'subtitle',
      label: 'Subtitle',
      component: 'text',
    },
  ],
}

const itemTemplates = {
  menuiconcard: {
    ...baseItem,
    label: 'Menu Icon Card Item',
    defaultItem: {
      ...baseItem.defaultItem,
      image: {
        src: 'https://tools--dev.cms.eiidev.com/placeholder/200/200',
        alt: '',
      },
      href: '#',
      description:
        '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci expedita doloremque temporibus ipsum illo vero aut voluptas laborum ex dignissimos numquam at odio, aspernatur voluptatum, provident sit nesciunt! Dicta, natus.</p>',
    },
    fields: [
      ...baseItem.fields,
      {
        name: 'icon',
        label: 'Icon',
        component: 'select',
        options: ['🚀', '🚫', '✅', '⭐️'],
      },
      {
        name: 'image',
        label: 'Image',
        component: 'group',
        fields: [
          {
            name: 'src',
            label: 'Image',
            component: 'image',
            description: 'Image description',
            clearable: true,
            parse: (media) => {
              if (!media) {
                return
              }
              return constructAssetUrl(media.id)
            },
            previewSrc: (src) => {
              return src
            },
          },
          {
            name: 'alt',
            label: 'Image Alt',
            component: 'text',
          },
          {
            name: 'width',
            label: 'Image Width',
            component: 'text',
          },
          {
            name: 'height',
            label: 'Image Height',
            component: 'text',
          },
          {
            name: 'src',
            label: 'Image URL',
            component: 'text',
          }
        ],
      },
      {
        name: 'href',
        label: 'Link',
        component: 'text',
      },
      {
        name: 'description',
        label: 'Description',
        component: 'html',
      },
    ],
  },
}

export const MENU_ICON_CARD_ITEM_BLOCKS = {
  menuiconcard: {
    Component({_name, index, data, cms}) {
      return (
        <div className="menu column">
          <ItemWrapper index={index} cms={cms}>
            <a className="menu-icon-card menu-card-item-border-0-1" href={data.href} style={{textAlign: 'center'}}>
              <div className="menu-card-item-border-0-2"></div>
              <div>
                <SectionImage
                  name="image"
                  src={data.image?.src}
                  mobile={data.image?.mobile}
                  alt={data.image?.alt}
                  priority={data.image?.priority}
                  sizes={data.image?.sizes}
                  width={data.image?.width}
                  height={data.image?.height}
                  className="menu-card-item-image menu-icon-card-item-image"
                  cms={cms}
                />
                <div className="menu-card-item menu-icon-card-item">
                  <div className="menu-icon-card-content">
                    <h3>
                      <span className="menu-item-title menu-card-item-title menu-icon-card-item-title heading-3">
                        <SectionText name="title" text={data.title} cms={cms}/>
                      </span>
                      <span className="menu-item-subtitle menu-card-item-subtitle menu-icon-card-item-subtitle heading-4">
                        <SectionText name="subtitle" text={data.subtitle} cms={cms}/>
                      </span>
                    </h3>
                    <p className="menu-icon-card-item-description paragraph">
                      <RichtextWithVariables
                        name="description"
                        richtext={data.description}
                        cms={cms}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </ItemWrapper>
        </div>
      )
    },
    template: itemTemplates.menuiconcard,
  },
}

function ItemWrapper(props) {
  return (
    <LucidBlocksControls
      index={props.index}
      focusRing={{offset: -9, borderRadius: 4}}
      insetControls={false}
      cms={props.cms}
      className="full-width"
    >
      {props.children}
    </LucidBlocksControls>
  )
}
