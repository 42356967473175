import TwoColumnNarrowWideListImages from '@/components/sections/TwoColumnNarrowWideListImages/index'

export const styleCore = {
  config: {
    gutter: {
      content: {
        row: {
          content: 'number',
          default: 2,
          unit: 'rem',
          min: 0,
          max: 10,
        },
        column: {
          content: 'number',
          default: 4,
          unit: 'rem',
          min: 0,
          max: 10,
        },
      },
    },
  },
  target: {
    componentName: 'twocolumnnarrowwidelistimages',
  },
  css: `
  {target} .column-wide .intro + .items,
  {target} .column-wide .outro {
    padding-top: {gutter.row};
  }
  {target} .column-wide .section-richtext {
    padding-left: {gutter.column};
  }
  `,
}
