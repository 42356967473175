import {createContext, PropsWithChildren, useReducer} from 'react'
import reducer, {ActionTypes} from './reducer'
import {StyleCoreSiteProvider} from '@/src/state/site/StyleCoreStore'

export type ThemeState = {
  themeName: string,
  components: {
    [key: string]: string
  }
}

const initialState: ThemeState = {
  themeName: '',
  components: {}
}

export type ThemesDispatcher = (arg: {type: typeof ActionTypes[number], payload: Partial<ThemeState>}) => void

type LucidThemesContextInterface = readonly [ThemeState, ThemesDispatcher]

export const SiteThemeContext = createContext<LucidThemesContextInterface>([initialState, () => {}])

export const SiteThemeProvider = ({children}: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <SiteThemeContext.Provider value={[state as ThemeState, dispatch]}>
      <StyleCoreSiteProvider>
        {children}
      </StyleCoreSiteProvider>
    </SiteThemeContext.Provider>
  )
}
