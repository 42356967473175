import FeaturesList from '@/components/sections/FeaturesList/index'

export const styleCore = {
  config: {
    image: {
      content: {
        width: {
          default: 200,
          content: 'number',
          min: 0,
          unit: 'px',
          description: 'The max width of the image',
        },
      }
    }
  },
  target: {
    componentName: 'featureslist',
  },
  css: `
    {target} .features-list-item-image {
      max-width: {image.width};
    }
  `
}
