import React, {useEffect, useState} from 'react'
import type {BlockComponentProps, InlineBlocksProps} from '@einsteinindustries/react-tinacms-inline'
import type {ComponentLoader} from '@/components/shared/types'
import {useDebounce} from 'react-use'

export type LucidBlockComponentProps = React.FC<BlockComponentProps & {cms: boolean}>

export type LucidTransformedBlock = {
  component: LucidBlockComponentProps,
  data: Record<string, any>,
  name: string,
}

interface LucidInlineBlocksProps extends InlineBlocksProps {
  itemProps: { [key: string]: any }
}

export default function LucidInlineBlocks(props: LucidInlineBlocksProps) {
  const [tinaComponent, setTinaComponent] = useState<ComponentLoader<LucidInlineBlocksProps>>({
    Component: null
  })

  const [debouncedProps, setDebounceProps] = useState(props)

  useEffect(() => {
    async function loadTina() {
      const {InlineBlocks} = await import('@einsteinindustries/react-tinacms-inline')
      setTinaComponent({Component: InlineBlocks})
    }

    if (props.itemProps?.cms) {
      loadTina()
    }

    return () => {
      setDebounceProps(props)
    }

  }, [])

  useDebounce(() => {
    if (props.itemProps) {
      setDebounceProps((prev) => {
        return {...prev, ...props}
      })
    }
  }, 500, [props])

  if (tinaComponent.Component) {
    return <tinaComponent.Component {...debouncedProps}/>
  }

  if (typeof props.itemProps !== 'object') throw new Error(`Missing itemProps for ${props.name}`)

  const components: LucidTransformedBlock[] = []

  for (const [prospectTemplate, data] of Object.entries(props.itemProps)) {
    if (prospectTemplate === props.name && Array.isArray(data)) {
      for (const {_template, ...templateBuild} of data) {
        /**
         * In some instances, when working with a front end component, initial data may not be available
         * This is mostly not a problem except for navigation and maybe a couple others
         * that rely on current db data to generate menus and links
         *
         * We might be able to remove this check if we instead pass all db content
         * from the backend as initial state
         */
        if (typeof props.blocks[_template] === 'undefined') continue
        const data = {...templateBuild, _template}
        components.push({component: props.blocks[_template].Component, data, name: _template})
      }
    }
  }

  return (
    <div className={props.className}>
      {components.map((block, i) => {
        const BlockComponent = block.component
        return (
          <BlockComponent
            {...props.itemProps}
            name={block.name}
            data={block.data ?? []}
            key={i}
            index={i}
            cms={false}
          />
        )
      })}
    </div>
  )
}
