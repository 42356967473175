import {GlobalOptions} from './GlobalOptions'

export const DisplayModeOptions = [
    ...GlobalOptions,
    {
        label: 'Block',
        value: 'block'
    },
    {
        label: 'Inline',
        value: 'inline'
    },
    {
        label: 'Inline Block',
        value: 'inline-block'
    },
    {
        label: 'Flex',
        value: 'flex'
    },
    {
        label: 'Inline Flex',
        value: 'inline-flex'
    },
    {
        label: 'Grid',
        value: 'grid'
    },
    {
        label: 'Inline Grid',
        value: 'inline-grid'
    },
    {
        label: 'Table',
        value: 'table'
    },
]