import SchemaReview from '../shared/SchemaReview'
import {Button, Image, Modal, Text, useModal} from '@nextui-org/react'
import ReviewPicker from './ReviewPicker'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import constructAssetUrl from '../../editor/shared/constructAssetUrl'
import {LucidBlocksControls} from '@/components/sections/shared/LucidInlineGroups'

// TODO: fix default item not showing

// export const templateName = 'reviews'

// TODO: Use actual `cms`

const schemaValues = {
  nameLocalBusiness: 'Practice Name',
  imageLocalBusiness: 'https://placeimg.com/200/200/tech',
  telephoneLocalBusiness: '(866) 585-2157',
  streetAddressLocalBusiness: '6825 Flanders Dr Ste 110',
  addressLocalityLocalBusiness: 'San Diego',
  addressRegionLocalBusiness: 'CA',
  postalCodeLocalBusiness: '92121',
  addressCountryLocalBusiness: 'USA',
}

// Templates to use in the parent's block config and the InlineBlocks component
const itemTemplates = {
  review: {
    label: 'Custom Review',
    defaultItem: {
      name: 'Albert Einstein',
      rating: 5,
      review:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci expedita doloremque temporibus ipsum illo vero aut voluptas laborum ex dignissimos numquam at odio.',
      avatar_url: 'https://ui-avatars.com/api/?size=70&name=Albert+Einstein',
      avatar_alt: 'Albert Einstein',
      source_logo: 'https://s3.amazonaws.com/flextemplates/fragments/review/google-logo.svg',
      source_logo_alt: 'Google',
      source_link_text: 'Google',
      source_link_url: 'https://www.google.com',
    },
    itemProps: (item) => {
      if (!item.name) {
        return
      }

      return {label: `Review: ${item.name}`}
    },
    fields: [
      {
        name: 'name',
        label: 'Name',
        component: 'text',
      },
      {
        name: 'date',
        label: 'Date',
        component: 'text',
      },
      {
        name: 'location',
        label: 'Location',
        component: 'text',
      },
      {
        name: 'rating',
        label: 'Rating',
        component: 'radio-group',
        variant: 'radio',
        direction: 'vertical',
        options: [
          {label: '★☆☆☆☆', value: 1},
          {label: '★★☆☆☆', value: 2},
          {label: '★★★☆☆', value: 3},
          {label: '★★★★☆', value: 4},
          {label: '★★★★★', value: 5},
        ],
      },
      {
        name: 'review',
        label: 'Review',
        component: 'html',
      },
      {
        name: 'avatar_url',
        label: 'Avatar Image',
        component: 'image',
        description: 'Image description',
        clearable: true,
        parse: (media) => {
          if (!media) {
            return
          }
          return constructAssetUrl(media.id)
        },
        previewSrc: (src) => {
          return src
        },
      },
      {
        name: 'avatar_alt',
        label: 'Avatar Alt Text',
        component: 'text',
      },
      {
        name: 'source_logo',
        label: 'Source Logo',
        component: 'image',
        clearable: true,
        parse: (media) => {
          if (!media) {
            return
          }
          return constructAssetUrl(media.id)
        },
        previewSrc: (src) => {
          return src
        },
      },
      {
        name: 'source_alt',
        label: 'Source Alt Text',
        component: 'text',
      },
      {
        name: 'source_link_text',
        label: 'Source Link Text',
        component: 'text',
      },
      {
        name: 'source_link_url',
        label: 'Source Link URL',
        component: 'text',
      },
    ],
  },
}

export const CUSTOM_BLOCKS = {
  Component({index, data, cms}) {
    return (
      <ReviewItem
        index={index}
        data={data}
        cms={cms}
      />
    )
  },
  template: itemTemplates.review
}

export const IMPORTED_BLOCKS = {
  template: {
    label: 'Imported Review',
    defaultItem: {
        imported_reviews: {}
    },
    fields: [
      {
        name: 'imported_reviews',
        label: 'imported_reviews',
        component: (form) => {
          const ModalWrapper = () => {
            const {setVisible, bindings} = useModal()
            return <>
              <Button style={{
                width: '100%',
              }} auto size="lg" onPress={() => setVisible(true)}>Browse Reviews</Button>
              <Modal
                fullScreen
                scroll
                width="600px"
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                {...bindings}
              >
                <Modal.Header>
                  <Text id="modal-title" size={18}>
                    Browse Reviews
                  </Text>
                </Modal.Header>
                <Modal.Body>
                  <ReviewPicker
                    onChange={form.input.onChange}
                    value={form.input.value}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button auto flat color="error" onPress={() => setVisible(false)}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          }

          return <ModalWrapper/>
        }
      }
    ]
  },
  Component({index,cms,data}) {
    if (!data['imported_reviews']) {
      return null
    }
    return (
      <ReviewItem
        index={index}
        cms={cms}
        data={data['imported_reviews']}
      />
    )
  }
}

function ReviewItem({index, data, cms}) {
  return (
    <ItemWrapper cms={cms} index={index}>
      <SchemaReview
        datePublishedReview={data.date}
        descriptionReview={data.description}
        imageReview={data.avatar_url}
        namePerson={data.name}
        bestRating="5"
        worstRating="1"
        ratingValue={data.rating}
        nameLocalBusiness={schemaValues.nameLocalBusiness}
        imageLocalBusiness={schemaValues.imageLocalBusiness}
        telephoneLocalBusiness={schemaValues.telephoneLocalBusiness}
        streetAddressLocalBusiness={schemaValues.streetAddressLocalBusiness}
        addressLocalityLocalBusiness={
          schemaValues.addressLocalityLocalBusiness
        }
        addressRegionLocalBusiness={schemaValues.addressRegionLocalBusiness}
        postalCodeLocalBusiness={schemaValues.postalCodeLocalBusiness}
        addressCountryLocalBusiness={
          schemaValues.addressCountryLocalBusiness
        }
      />
        <div className="reviews-item__header">
          <Image
              src={data.avatar_url}
              alt={data.avatar_alt}
            className="reviews-item__avatar-wrapper"
          />
          <div className="reviews-item__info">
            <p className="reviews-item__name">
              {data.name}
            </p>
            <p className="reviews-item__location_id ">
              {data.location}
            </p>
            <p className="reviews-item__date">
              {data.date}
            </p>
            <div className="reviews-item__rating">
              <span className="reviews-item__stars" style={{
                width: data.rating * 20 + '%',
              }}></span>
            </div>
          </div>
          <a className="reviews-item__source" href={data.source_link_url} target="_blank" rel="noreferrer">
            <img
                src={data.source_logo}
                alt={data.avatar_alt}
                className="reviews-item__avatar-wrapper"
            />
          </a>
        </div>
        <div className="reviews-item__description paragraph">
          <RichtextWithVariables richtext={data.review} cms={cms} name={'review'} />
          <a className="link reviews-item__link" href={data.source_link_url} target="_blank" rel="noreferrer">
            View on {data.source_link_text}
          </a>
        </div>
    </ItemWrapper>
  )
}

function ItemWrapper(props) {
  return (
      <div className="reviews-item">
      <LucidBlocksControls
          index={props.index}
          cms={props.cms}
          focusRing={{offset: 16, borderRadius: 4}}
          insetControls={false}
        >
          {props.children}
      </LucidBlocksControls>
      </div>
  )
}
