import type {BlocksFieldDefinititon} from '@einsteinindustries/tinacms-fields'
import type {FormApi} from 'final-form'
import {useEffect, useState} from 'react'
import {getLocationById, mapLocations, prepareMapProperties} from '../Location/utils'
import uploadMapImage from './uploadMapImage'
import {useLucidContext} from '@/src/state/ServerSideStore'

type MapImageProps = {
  form: FormApi
  field: BlocksFieldDefinititon
  cms: boolean
}

const MapImage = ({form, field, cms}: MapImageProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [lastLocationId, setLastLocationId] = useState<string>('')
  const [lastCustomCoordinates, setLastCustomCoordinates] = useState<string>('')

  const [{site, site_contents}] = useLucidContext(cms)
  const locations = mapLocations((site_contents?.practice as {[key: string]: any})?.locations ?? [])

  const updateMapImage = async (locationId: string, customCoordinates: string) => {
    setIsLoading(true)
    const locationData = getLocationById(locationId, locations)

    if (locationData) {
      const {mapCenter, mapAddress} = prepareMapProperties({customCoordinates}, locationData)
      const siteId = site?.id ?? ''
      try {
        const uploadedImageUrl = await uploadMapImage({
          site_id: siteId,
          center: mapCenter,
          address: mapAddress ?? ''
        })

        if (uploadedImageUrl) {
          form.change(field.name, uploadedImageUrl)
        }
      } catch (error) {
        console.error('Error fetching map image:', error)
      }
    }
    setIsLoading(false)
  }

  useEffect(() => {
    const currentLocationId = form.getFieldState(field.name.replace('map_image', 'location'))?.value
    const currentCustomCoordinates = form.getFieldState(field.name.replace('map_image', 'customCoordinates'))?.value

    if (currentLocationId !== lastLocationId || 
      currentCustomCoordinates !== lastCustomCoordinates) {
      setLastLocationId(currentLocationId)
      setLastCustomCoordinates(currentCustomCoordinates)
      updateMapImage(currentLocationId, currentCustomCoordinates)
    }
  }, [form, field.name, locations])

  const mapImageUrl = form.getFieldState(field.name)?.value

  return (
    <div>
      {isLoading ? <p>Loading map image...</p> : null}
      {mapImageUrl && <img style={{paddingBottom: '10px'}} src={form.getFieldState(field.name)?.value || ''} alt="Map Image" />}
    </div>
  )
}

export default MapImage
