import {AggregateRating, Integer} from 'schema-dts'
import {jsonLdScriptProps} from 'react-schemaorg'
import Head from 'next/head'

type Props = {
  name: string
  image: string
  telephone: string
  streetAddress: string
  addressLocality: string
  addressRegion: string
  postalCode: string
  addressCountry: string
  ratingValue: string
  reviewCount: Integer
}

export default function SchemaAggregateRating({
  name,
  image,
  telephone,
  streetAddress,
  addressLocality,
  addressRegion,
  postalCode,
  addressCountry,
  ratingValue,
  reviewCount,
}: Props) {
  return (
    <Head>
      <script
        {...jsonLdScriptProps<AggregateRating>({
          '@context': 'https://schema.org',
          '@type': 'AggregateRating',
          itemReviewed: {
            '@type': 'LocalBusiness',
            name: name,
            image: image,
            telephone: telephone,
            address: {
              '@type': 'PostalAddress',
              streetAddress: streetAddress,
              addressLocality: addressLocality,
              addressRegion: addressRegion,
              postalCode: postalCode,
              addressCountry: {
                '@type': 'Country',
                name: addressCountry,
              },
            },
            priceRange: '$$$',
          },
          ratingValue: ratingValue,
          reviewCount: reviewCount,
        })}
      />
    </Head>
  )
}
