import {CUSTOM_BLOCKS, IMPORTED_BLOCKS} from './config'
import SchemaAggregateRating from '../shared/SchemaAggregateRating'
import Section from '../shared/Section'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import {useLucidContext} from '@/src/state/ServerSideStore'
import SectionButton from '../shared/SectionButton'
import {styleCore} from './stylecore'
import {presets} from '@/components/sections/Reviews/presets'

const schemaValues = {
  image: 'https://placeimg.com/200/200/tech',
  telephone: '(866) 585-2157',
  streetAddress: '6825 Flanders Dr Ste 110',
  addressLocality: 'San Diego',
  addressRegion: 'CA',
  postalCode: '92121',
  addressCountry: 'USA',
  ratingValue: '5',
  reviewCount: '10',
}

export default function Reviews(props) {
  const [{site_contents}] = useLucidContext(props.cms)
  const practice_name = site_contents?.practice?.name ?? ''

  const {
    title,
    subtitle,
    intro,
    outro,
    button,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    cms,
    meta
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' section reviews-section stack'}
      name="reviews"
      section_background={section_background}
    >

      <SchemaAggregateRating
        name={practice_name}
        image={schemaValues.image}
        telephone={schemaValues.telephone}
        streetAddress={schemaValues.streetAddress}
        addressLocality={schemaValues.addressLocality}
        addressRegion={schemaValues.addressRegion}
        postalCode={schemaValues.postalCode}
        addressCountry={schemaValues.addressCountry}
        ratingValue={schemaValues.ratingValue}
        reviewCount={schemaValues.reviewCount}
      />

      <div className="section-container">

        <div className="section-content reviews_gutter_row_1 reviews_gutter_column_1">

          <div className="section-heading">
            <h1>
          <span className="title heading-1">
            <RichtextWithVariables richtext={title} cms={cms} name={'title'}/>
          </span>
              <span className="subtitle heading-2">
            <RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/>
          </span>
            </h1>
          </div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext paragraph">
                  <RichtextWithVariables richtext={intro} cms={cms} name={'intro'}/>
                </div>
              </div>
            </div>
          </div>
          <LucidInlineBlocks
            itemProps={props}
            className="reviews-wrapper" name={'review_item'}
            blocks={{imported: IMPORTED_BLOCKS, custom: CUSTOM_BLOCKS}}
          />
          <div className="section-outro">
            <div className="row">
              <div className="column section-richtext paragraph">
                <RichtextWithVariables richtext={outro} cms={cms} name={'outro'}/>
              </div>
            </div>
          </div>
          {
            button?.url && <div className="section-button button">
                <SectionButton cms={cms} href={button?.url} name={'button'} label={button?.label}/>
              </div>
          }

        </div>
      </div>
    </Section>
  )
}

Reviews.StyleCore = styleCore
Reviews.presets = presets

