import {LocalBusiness, OpeningHoursSpecification} from 'schema-dts'
import {jsonLdScriptProps} from 'react-schemaorg'
import Head from 'next/head'

type Props = {
  name: string
  image: string
  streetAddress: string
  addressLocality: string
  addressRegion: string
  postalCode: string
  addressCountry: string
  telephone: string
  openingHours: string
}

export default function SchemaLocalBusiness({
  name,
  image,
  streetAddress,
  addressLocality,
  addressRegion,
  postalCode,
  addressCountry,
  telephone,
  openingHours,
}: Props) {
  return (
    <Head>
      <script
        {...jsonLdScriptProps<LocalBusiness>({
          '@context': 'https://schema.org',
          '@type': 'LocalBusiness',
          name: name,
          image: image,
          address: {
            '@type': 'PostalAddress',
            streetAddress: streetAddress,
            addressLocality: addressLocality,
            addressRegion: addressRegion,
            postalCode: postalCode,
            addressCountry: {
              '@type': 'Country',
              name: addressCountry,
            },
          },
          telephone: telephone,
          openingHours: openingHours,
          priceRange: '$$$',
        })}
      />
    </Head>
  )
}
