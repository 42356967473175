import {Copy, CopySuccess} from 'iconsax-react'
import {useEffect, useState} from 'react'

const iconStyle = {
    cursor: 'pointer',
    color: '#433e52'
}

interface CopyToClipboardProps {
    textToCopy: string
}

export default function CopyToClipboard({
    textToCopy
}: CopyToClipboardProps) {
    const [copied, setCopied] = useState(false)

    let timeoutFunction: NodeJS.Timeout | null = null

    const copyToClipboard = (textToCopy: string) => {
        navigator.clipboard.writeText(textToCopy)
        setCopied(true)
        timeoutFunction = setTimeout(() => {
            setCopied(false)
        }, 2000)
    }

    useEffect(() => {
        return clearTimeout(timeoutFunction as NodeJS.Timeout)
    })

    return (
        copied ? (
            <CopySuccess
                size={20}
                style={iconStyle}
            />
        ) : (
            <Copy
                size={20}
                style={iconStyle}
                onClick={() => copyToClipboard(textToCopy)}
            />
        )
    )
}
