import HeroTagline from '@/components/sections/HeroV2/index'
import {MarginFields} from '@/components/sections/shared/stylecore/fields/MarginFields'

export const styleCore = {
  config: {
    heights: {
      content: {
        section: {
          content: {
            enableHeight: {
              content: 'toggle',
              default: false,
              description: 'Enable height as percentage of viewport',
            },
            height: {
              content: 'number',
              default: 100,
              unit: 'vh',
              description: 'Height of the section as percentage of viewport',
            },
          },
        },
      },
    },
    positions: {
      content: {
        buttons: {
          content: {
            horizontalPosition: {
              content: 'select',
              default: 'flex-start',
              options: [
                {value: 'flex-start', label: 'Left'},
                {value: 'center', label: 'Center'},
                {value: 'flex-end', label: 'Right'},
              ],
            },
          },
        },
        caption: {
          content: {
            horizontalPosition: {
              content: 'select',
              default: 'flex-end',
              options: [
                {value: 'flex-start', label: 'Left'},
                {value: 'center', label: 'Center'},
                {value: 'flex-end', label: 'Right'},
              ],
            },
            verticalPosition: {
              content: 'select',
              default: 'flex-end',
              options: [
                {value: 'flex-start', label: 'Top'},
                {value: 'center', label: 'Center'},
                {value: 'flex-end', label: 'Bottom'},
              ],
            },
          },
        },
        tagline: {
          content: {
            horizontalPosition: {
              content: 'select',
              default: 'center',
              options: [
                {value: 'flex-start', label: 'Left'},
                {value: 'center', label: 'Center'},
                {value: 'flex-end', label: 'Right'},
              ],
            },
            verticalPosition: {
              content: 'select',
              default: 'center',
              options: [
                {value: 'flex-start', label: 'Top'},
                {value: 'center', label: 'Center'},
                {value: 'flex-end', label: 'Bottom'},
              ],
            },
          },
        },
      },
    },
    widths: {
      content: {
        tagline: {
          content: {
            width: {
              content: 'number',
              default: 100,
              unit: '%',
              min: 0,
              max: 100,
            },
          },
        },
      },
    },
  },
  target: {
    componentName: 'herov2',
  },
  css: `
  {target} {
    height: {heights.section.enableHeight ? {heights.section.height} : inherit};
  }
  {target} .section-tagline {
    justify-content: {positions.tagline.horizontalPosition};
    align-items: {positions.tagline.verticalPosition};
  }
  {target} .caption {
    justify-content: {positions.caption.horizontalPosition};
    align-items: {positions.caption.verticalPosition};
  }
  {target} .section-buttons {
    justify-content: {positions.buttons.horizontalPosition};
  }
  {target} .tagline-width {
    max-width: {widths.tagline.width};
  }
  `,
}
