import {createContext, PropsWithChildren, useReducer} from 'react'
import reducer, {ActionTypes} from '@/src/state/site/reducer'
import type {TypographyGroupMap} from '@/components/editor/typography/types'
import type {StyleCoreMap, StyleCorePresetMap, StyleCoreRenderedMap} from '@/components/shared/StyleCore'
import type {Contents} from '@/components/shared/types'
import {TypekitFetched} from '@/components/editor/typography/types'

/**
 * TODO: DO NOT use Maps in Store/dispatch/reducer designs
 *   1. Maps are not serializable
 *   2. There is tons of code associated with StyleCore where you are doing StyleCore.map.set('someKey',someObject')
 *      * This is mutating the map, so effectively, you have detached the functionality from react's natural cycles
 *      * This creates issues such as accessing an object that has not been really updated via reducer
 *      * Calling dispatch and passing StyleCore to it executes a react cycle, but your code continued to execute
 *      * That is because react's dispatches prevent a a react hook/component from continuing execution,
 *      * instead, react triggers a new rendering/cycle
 *   3. This whole piece will need to be refactored at a later time, it creates multiple issues
 */
export type StyleCoreStateContents = {
  initialized: boolean
  map: StyleCoreMap,
  presets: StyleCorePresetMap,
  typography: {
    map: TypographyGroupMap,
    typekitKits: TypekitFetched[]
  },
  rendered: {
    map: StyleCoreRenderedMap
  }
}

export type StyleCoreState = {
  StyleCore: StyleCoreStateContents,
  site_build_contents: Contents[],
  section_contents: {
    [id: string]: Contents[]
  }
}

const initialStyleCoreState: StyleCoreState = {
  StyleCore: {
    initialized: false,
    map: new Map(),
    presets: new Map(),
    typography: {
      map: new Map(),
      typekitKits: []
    },
    rendered: {
      map: new Map()
    }
  },
  section_contents: {},
  site_build_contents: []
}

export type StyleCoreDispatcher = (
  arg: {
    type: typeof ActionTypes[number],
    payload: Partial<{ StyleCore: Partial<StyleCoreState['StyleCore']> } | StyleCoreState>
  }) => void

type StyleCoreContextInterface = readonly [StyleCoreState, StyleCoreDispatcher]

export const StyleCoreContext = createContext<StyleCoreContextInterface>([initialStyleCoreState, () => {}])

export const StyleCoreSiteProvider = ({children}: PropsWithChildren<any>) => {
  const [styleCoreState, dispatchStyleCore] = useReducer(reducer, initialStyleCoreState)

  return (
      <StyleCoreContext.Provider value={[styleCoreState as StyleCoreState, dispatchStyleCore]}>
        {children}
      </StyleCoreContext.Provider>
  )
}

