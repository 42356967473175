import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import Section from '../shared/Section'
import SectionButton from '../shared/SectionButton'
import {styleCore} from './stylecore'

export default function HeroV2(props) {
  const {
    supertitle,
    title,
    subtitle,
    richtext_1,
    richtext_2,
    caption,
    button_1,
    button_2,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props

  let HeroButton1 = null
  if (button_1?.url && button_1?.label) {
    HeroButton1 = (
      <div className="button-1 button-column column">
        <SectionButton
          label={button_1?.label}
          href={button_1?.url}
          cms={cms}
          name="button_1"
        />
      </div>
    )
  }
  let HeroButton2 = null
  if (button_2?.url && button_2?.label) {
    HeroButton2 = (
      <div className="button-2 button-column column">
        <SectionButton
          label={button_2?.label}
          href={button_2?.url}
          cms={cms}
          name="button_2"
        />
      </div>
    )
  }
  let ContentAboveButton = null
  if (richtext_1) {
    ContentAboveButton = (
      <div className="richtext-1 section-richtext paragraph">
        <RichtextWithVariables
          richtext={richtext_1}
          cms={cms}
          name="richtext_1"
        />
      </div>
    )
  }
  let ContentBelowButton = null
  if (richtext_2) {
    ContentBelowButton = (
      <div className="richtext-2 section-richtext paragraph">
        <RichtextWithVariables
          richtext={richtext_2}
          cms={cms}
          name="richtext_2"
        />
      </div>
    )
  }
  let CaptionElement = null
  if (caption) {
    CaptionElement = (
      <div className="caption">
        <RichtextWithVariables
          richtext={caption}
          cms={cms}
          name="caption"
        />
      </div>
    )
  }

  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' section hero-v2-section back-stack bar-enabled'}
      name="herov2"
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content section-tagline flexbox-alignment">
          <div className="tagline-width">
            <div className="section-heading">
              <h2>
                <span className="supertitle heading-3">
                  <RichtextWithVariables
                    richtext={supertitle}
                    cms={cms}
                    name="supertitle"
                  />
                </span>
                <span className="title heading-1">
                  <RichtextWithVariables
                    richtext={title}
                    cms={cms}
                    name="title"
                  />
                </span>
                <span className="subtitle heading-2">
                  <RichtextWithVariables
                    richtext={subtitle}
                    cms={cms}
                    name="subtitle"
                  />
                </span>
              </h2>
            </div>
            {ContentAboveButton}
            <div className="section-buttons row">
              {HeroButton1}
              {HeroButton2}
            </div>
            {ContentBelowButton}
          </div>
          {CaptionElement}
        </div>
      </div>
    </Section>
  )
}

HeroV2.StyleCore = styleCore
