export const BackgroundFields = {
    createFields(defaults = {}) {
        let defaultFields = {
            enabled: false,
            color: '#ffffff',
        }

        defaultFields = Object.assign(defaultFields, defaults)

        return {
            background: {
                content: {
                    enabled: {
                        content: 'toggle',
                        default: false,
                    },
                    color: {
                        content: 'color',
                        default: '#ffffff',
                    }
                }
            }
        }
    },
    createPartialCSS(prefix = '') {
        return `
            background: {${prefix}background.enabled ? {${prefix}background.color} : 'none'};
        `
    }
}