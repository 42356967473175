import {Number, Review} from 'schema-dts'
import {jsonLdScriptProps} from 'react-schemaorg'
import Head from 'next/head'

type Props = {
  nameLocalBusiness: string
  imageLocalBusiness: string
  telephoneLocalBusiness: string
  streetAddressLocalBusiness: string
  addressLocalityLocalBusiness: string
  addressRegionLocalBusiness: string
  postalCodeLocalBusiness: string
  addressCountryLocalBusiness: string
  namePerson: string
  datePublishedReview: string
  descriptionReview: string
  imageReview: string
  bestRating: Number
  worstRating: Number
  ratingValue: Number
}

export default function SchemaReview({
  datePublishedReview,
  descriptionReview,
  imageReview,
  namePerson,
  bestRating,
  worstRating,
  ratingValue,
  nameLocalBusiness,
  imageLocalBusiness,
  telephoneLocalBusiness,
  streetAddressLocalBusiness,
  addressLocalityLocalBusiness,
  addressRegionLocalBusiness,
  postalCodeLocalBusiness,
  addressCountryLocalBusiness,
}: Props) {
  return (
    <Head>
      <script
        {...jsonLdScriptProps<Review>({
          '@context': 'https://schema.org',
          '@type': 'Review',
          datePublished: datePublishedReview,
          description: descriptionReview,
          image: imageReview,
          author: {
            '@type': 'Person',
            name: namePerson,
          },
          reviewRating: {
            '@type': 'Rating',
            bestRating: bestRating,
            worstRating: worstRating,
            ratingValue: ratingValue,
          },
          itemReviewed: {
            '@type': 'LocalBusiness',
            name: nameLocalBusiness,
            image: imageLocalBusiness,
            telephone: telephoneLocalBusiness,
            address: {
              '@type': 'PostalAddress',
              streetAddress: streetAddressLocalBusiness,
              addressLocality: addressLocalityLocalBusiness,
              addressRegion: addressRegionLocalBusiness,
              postalCode: postalCodeLocalBusiness,
              addressCountry: {
                '@type': 'Country',
                name: addressCountryLocalBusiness,
              },
            },
            priceRange: '$$$',
          },
        })}
      />
    </Head>
  )
}
