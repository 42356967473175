import useSWRInfinite from 'swr/infinite'
import {lucidDataFetcherV2} from '@/graphql/fetchers'
import {Contents} from '@/components/shared/types'
import {useContext} from 'react'
import {LucidSiteContext} from '@/src/state/site/Store'
import useSWR, {SWRResponse} from 'swr'
import {CONTENTS} from '@/graphql/queries'
import {KeyedMutator} from 'swr/dist/types'

const PAGE_SIZE = 200

type UseAllContentsParams = {
    site_build_id?: string
    page_section_id?: string
    site_id?: string
}
const fetchAllContents = async (query: string, params: UseAllContentsParams) => {
    let allData: Contents[] = []
    let pageIndex = 0
    let fetchedData: {
        data: {
            [resolver: string]: Contents[];
        };
        errors?: Record<string, any>[] | undefined;
    }

    const variables = Object.entries(params).filter(([key, value]) => {
        return typeof value !== 'undefined' && value !== ''
    })

    if (variables.length === 0) {
        return []
    }
    do {
        const skip = pageIndex * PAGE_SIZE
        const variables = {...params, skip, take: PAGE_SIZE}
        fetchedData = await lucidDataFetcherV2(CONTENTS, variables)
        allData = [...allData, ...(fetchedData?.data?.contents ?? [])] // Accumulate the items
        pageIndex++
    } while (fetchedData?.data?.contents.length === PAGE_SIZE)

    return allData
}
export function useFetchAllContents(params: UseAllContentsParams): {
    data: Contents[]
    mutate: KeyedMutator<Contents[]>
} {
    const [site] = useContext(LucidSiteContext)
    const SWRRsep: SWRResponse<Contents[]> = useSWR(
        [CONTENTS, params],
        fetchAllContents,
        {revalidateOnFocus: site.revalidateOnFocus}
    )
    return {
        data: SWRRsep?.data ?? [],
        mutate: SWRRsep.mutate,
    }
}
