import {PositionOptions} from '../options/PositionOptions'
import {UnitOptions} from '../options/UnitOptions'

export const PositioningFields = {
    createFields(defaults = {}) {
        let defaultFields = {
            position: 'relative',
            positionalUnits: 'px',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        }
        defaultFields = Object.assign(defaultFields, defaults)
        return {
            positioning: {
                content: {
                    position: {
                        content: 'select',
                        default: defaultFields.position,
                        options: PositionOptions,
                    },
                    positionalUnits: {
                        content: 'select',
                        default: defaultFields.positionalUnits,
                        options: UnitOptions,
                    },
                    top: {
                        content: 'number',
                        default: defaultFields.top,
                    },
                    right: {
                        content: 'number',
                        default: defaultFields.right,
                    },
                    bottom: {
                        content: 'number',
                        default: defaultFields.bottom
                    },
                    left: {
                        content: 'number',
                        default: defaultFields.left,
                    }
                }
            }
        }
    },
    createPartialCSS(prefix = '') {
        return `
            position: {${prefix}positioning.position};
            top: {${prefix}positioning.top}{${prefix}positioning.positionalUnits};
            right: {${prefix}positioning.right}{${prefix}positioning.positionalUnits};
            bottom: {${prefix}positioning.bottom}{${prefix}positioning.positionalUnits};
            left: {${prefix}positioning.left}{${prefix}positioning.positionalUnits};
        `
    }
}