import {GlobalOptions} from './GlobalOptions'

export const PositionOptions = [
    {
        label: 'Static',
        value: 'static'
    },
    {
        label: 'Relative',
        value: 'relative'
    },
    {
        label: 'Absolute',
        value: 'absolute'
    },
    {
        label: 'Fixed',
        value: 'fixed'
    },
    {
        label: 'Sticky',
        value: 'sticky'
    },
    ...GlobalOptions
]