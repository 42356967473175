import SectionText from '../shared/SectionText'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import {LucidBlocksControls} from '../shared/LucidInlineGroups'

// Base item for all the other item types to pull from
const baseItem = {
  label: 'Item',
  defaultItem: {
    title: 'Title',
    description:
      '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>',
  },
  itemProps: (item) => {
    if (!item.title && !item.description) {
      return
    }

    return {label: item.title || item.description}
  },
  // TODO: Create a set of default fields
  fields: [
    {
      name: 'title',
      label: 'Title',
      component: 'html',
    },
    {
      name: 'description',
      label: 'Content',
      component: 'html',
    },
  ],
}

// Templates to use in the parent's block config and the InlineBlocks component
const itemTemplates = {
  twocolumnnarrowwidelisttitle: {
    ...baseItem,
    label: 'Item',
    defaultItem: {
      ...baseItem.defaultItem,
    },
    fields: [...baseItem.fields],
  },
}

export const TWO_COLUMN_NARROW_WIDE_LIST_TITLE_ITEM_BLOCKS = {
  twocolumnnarrowwidelisttitle: {
    Component({_name, index, data, cms}) {
      return (
        <>
          <div className="item">
            <ItemWrapper
              index={index}
              cms={cms}
            >
              <h3 className="list-title-label heading-3">
                <SectionText
                  name="label"
                  text={data.title}
                  cms={cms}
                />
              </h3>
              <div className="content paragraph">
                <RichtextWithVariables
                  name="description"
                  richtext={data.description}
                  cms={cms}
                />
              </div>
            </ItemWrapper>
          </div>
        </>
      )
    },
    template: itemTemplates.twocolumnnarrowwidelisttitle,
  },
}

function ItemWrapper(props) {
  return (
    <LucidBlocksControls
      index={props.index}
      cms={props.cms}
      focusRing={{offset: 16, borderRadius: 4}}
      insetControls={false}
    >
      {props.children}
    </LucidBlocksControls>
  )
}
