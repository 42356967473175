import {LucidBlocksControls} from '../shared/LucidInlineGroups'
import {Text} from '@nextui-org/react'
import React from 'react'
import LocationPicker from '../Location/LocationPicker'
import SectionButton from '../shared/SectionButton'
import styled from 'styled-components'
import {prepareMapProperties, useLocationData} from '../Location/utils'
import Helpers from '@/src/utils/shared/helpers'
import StaticMap from '../StaticMap'
import SchemaLocalBusiness from '../shared/SchemaLocalBusiness'
import MapImage from '../shared/MapImage'
import {HoursDisplay} from '../Location/utils'

const Row = styled.div`
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row wrap;
  align-items: top;
  text-align: center;
  margin-bottom: 3rem;

  .city,
  .state,
  .zipcode {
    display: inline;
  }
`

const Column = styled.div`
  display: flex;
  flex-flow: column wrap;
  flex: 1 1 auto;
  padding: 0 1rem;

  // TODO: Allow customizing these widths through StyleCore
  // https://einsteinindustries.atlassian.net/browse/LUC-1779
  @media (max-width: 665px) {
    &:first-child {
      order: 2;
    }

    &.location-map {
      order: 1;
    }
  }

  &.location-map {
    a {
      display: block;

      @media (max-width: 665px) {
        width: 100%;
        min-height: 300px;
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }
`

export const LOCATIONS_ITEM_BLOCKS = {
  template: {
    label: 'Locations',
    defaultItem: {
        location: '',
        map_image: '',
        customMapUrl: '',
        customCoordinates: '',
        link: '',
    },
    fields: [
      {
        name: 'location',
        label: 'Location',
        component: (form) => <LocationPicker onChange={form.input.onChange} value={form.input.value} />
      },
      {
        name: 'map_image',
        label: 'Map Image',
        component: MapImage
      },
      {
        name: 'customMapUrl',
        label: 'Custom Map URL',
        component: 'text',
        description: 'Optional. Overrides the default Google Maps URL to link to for this location.',
      },
      {
        name: 'customCoordinates',
        label: 'Custom Coordinates',
        component: 'text',
        description: 'Optional. Enter as "latitude, longitude" to override default address maps placement. Example: "37.7749, -122.4194".',
      },
      {
        name: 'link',
        label: 'Link',
        component: 'text',
        description: 'Optional. Link to a page with more information about this location.'
      }
    ]
  },
  Component({index, cms, data}) {
    return (
      <LocationsItem
        key={data.location.id}
        index={index}
        cms={cms}
        data={data}
        style={{marginBottom: '50px'}}
      />
    )
  }
}

function LocationsItem({index, cms, data}) {
  const {location, schemaProps} = useLocationData(data.location, cms)
  const {mapLink} = prepareMapProperties(data, location)
  if (!data.location) {
    return (
      <ItemWrapper index={index} cms={cms}>
        <p style={{padding: '20px'}}>Click edit to select a location.</p>
      </ItemWrapper>
    )
  }

  return (
    <ItemWrapper index={index} cms={cms}>
      <SchemaLocalBusiness {...schemaProps} />
      <Row>
        <Column>
          <h1>
            {data.link ? (
              <Text h2 name="title">
                <a
                  className="title-link"
                  href={data.link}
                  target="_blank"
                  rel='noopener noreferrer'
                >
                  {location?.name}
                </a>
              </Text>
            ) : (
              <Text h2 name="title">{location?.name}</Text> 
            )}
          </h1>
          <div className="address">
            <Text name="address">{location?.address}</Text>
          </div>
          <div className="address2">
            <Text name="address2">{location?.address2}</Text>
          </div>
          <div className="city_state">
            <Text name="city_state">{location?.city}, {location?.state} {location?.postal_code}</Text>
          </div>
          <div className="direction_button">
            <SectionButton
              name="directions"
              href={mapLink}
              label="Directions"
              cms={false}
              openInNewTab
            />
          </div>
          <div className="phone_button">
            <SectionButton
              name='phone'
              href={`tel:${location?.phone}`}
              label={Helpers.format.human.phone.format(location?.phone)}
              cms={false}
            />
          </div>
          <div className="office-hours paragraph">
            <HoursDisplay className="hours" hoursData={location?.hours} />
          </div>
        </Column>
        <Column className="location-map">
          <a href={mapLink} target="_blank" rel="noreferrer">
            <StaticMap
              mapImage={data.map_image}
              size="large"
            />
          </a>
        </Column>
      </Row>
    </ItemWrapper>
  )
}

function ItemWrapper(props) {
  return (
    <div className="locations-item">
      <LucidBlocksControls
        cms={props.cms}
        index={props.index}
        focusRing={{offset: -9, borderRadius: 4}}
        insetControls={false}
      >
        {props.children}
      </LucidBlocksControls>
    </div>
  )
}
