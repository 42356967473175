import React, {useEffect, useState} from 'react'
import type {StyleCoreRenderedMap, StyleCoreTarget} from '@/components/shared/StyleCore'
import type {SectionMeta} from '@/components/shared/types'
import type {StyleCoreCSSComponentProps} from '@/components/sections/shared/CMSSection'
import CssParser from '@/src/utils/CssParser'
import * as process from 'process'

type StyleManagerProps = {
  cms: boolean
  meta: SectionMeta
  styleCoreTarget: StyleCoreTarget
  css_overrides: string
  cssPrependClassName: string
  prerenderedStyles: StyleCoreRenderedMap
}

export function prependClassNameToCSS(cn: string, css?: string): string {
  if (!css) return ''

  const cssParser = new CssParser(css)
  const warnings = cssParser.getWarnings()
  /**
   * TODO: Display warnings on css editor instead
   */
  if (process.env.NODE_ENV !== 'production' && process.env.NEXT_PUBLIC_MUTE_WARNINGS !== 'true') {
    warnings.forEach(w => console.warn(w))
  }
  cssParser.tokensWalk((token) => {
    if (token.type === 'selector') {
      token.content = token.content.map(selector => `.${cn} ${selector}`)
    }
  })
  return cssParser.minify()
}

type SectionEditorLoader = { StyleCoreCSSComponent: null | React.FunctionComponent<StyleCoreCSSComponentProps> }

const StyleManager = ({cms, meta, styleCoreTarget, css_overrides, cssPrependClassName, prerenderedStyles}: StyleManagerProps) => {
  const prefixCss = prependClassNameToCSS(cssPrependClassName, css_overrides)

  const [styleCoreComponent, setStyleCoreComponent] = useState<SectionEditorLoader>({
    StyleCoreCSSComponent: null
  })

  useEffect(() => {
    const loadStyleCoreCSSComponent = async () => {
      const {StyleCoreCSSComponent} = await import('./CMSSection')
      setStyleCoreComponent({StyleCoreCSSComponent})
    }
    if (cms) {
      loadStyleCoreCSSComponent()
    }
  }, [])

  if (styleCoreComponent.StyleCoreCSSComponent) {
    return <>
      <styleCoreComponent.StyleCoreCSSComponent target={styleCoreTarget} cms={cms}/>
      <style jsx global>{`${prefixCss ?? ''}`}</style>
    </>
  } else {
    const mapElement = prerenderedStyles.get(styleCoreTarget?.identifier ?? '')
    let styles = meta?.style?.css
    if (typeof mapElement !== 'undefined') {
      styles = mapElement.css
    }
    return <>
      <style jsx global>{`${prefixCss ?? ''} ${styles ?? ''}`}</style>
    </>
  }
}

export default StyleManager
