export const GlobalOptions = [
    {
        label: 'Initial',
        value: 'initial'
    },
    {
        label: 'Inherit',
        value: 'inherit'
    },
    {
        label: 'Auto',
        value: 'auto'
    },
    {
        label: 'None',
        value: 'none'
    }
]