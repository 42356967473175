import HeroProfessional from '@/components/sections/HeroProfessional/index'

export const styleCore = {
  config: {
    gutter: {
      content: {
        column: {
          content: 'number',
          default: 3,
          unit: 'rem',
          min: 0,
          max: 10,
        },
      },
    },
  },
  target: {
    componentName: 'heroprofessional',
  },
  css: `
  {target} .section-richtext {
    padding-left: {gutter.column};
  }
  `,
}
