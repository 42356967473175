import {PositioningFields} from './PositioningFields'
import {DisplayModeOptions} from '../options/DisplayModeOptions'
import {OverflowOptions} from '../options/OverflowOptions'

export const DisplayFields = {
    createFields(defaults = {}) {
        let defaultFields = {
            mode: 'block',
            overflowMode: 'visible',
        }
        defaultFields = Object.assign(defaultFields, defaults)
        return {
            display: {
                content: {
                    mode: {
                        content: 'select',
                        default: defaultFields.mode,
                        options: DisplayModeOptions,
                        description: 'Block elements take up the full width of their container, and normally stack on top of each other. Inline elements only take up as much width as they need, and do not force new lines.',
                    },
                    overflowMode: {
                        content: 'select',
                        default: defaultFields.overflowMode,
                        options: OverflowOptions
                    },
                    ...(PositioningFields.createFields(defaults.positioning ?? {}))
                }
            }
        }
    },
    createPartialCSS(prefix = '') {
        return `
            display: {${prefix}display.mode};
            overflow: {${prefix}display.overflowMode};
            ${PositioningFields.createPartialCSS(prefix + 'display.')}
        `
    }
}