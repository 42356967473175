export const BoxShadowFields = {
    createFields(defaults = {}) {
        let defaultFields = {
            horizontalOffset: 0,
            verticalOffset: 0,
            blurRadius: 0,
            spreadRadius: 0,
            color: '#00000020',
        }
        defaultFields = Object.assign(defaultFields, defaults)
        return {
            boxShadow: {
                content: {
                    horizontalOffset: {
                        content: 'number',
                        default: defaultFields.horizontalOffset,
                        unit: 'px',
                    },
                    verticalOffset: {
                        content: 'number',
                        default: defaultFields.verticalOffset,
                        unit: 'px',
                    },
                    blurRadius: {
                        content: 'number',
                        default: defaultFields.blurRadius,
                        unit: 'px',
                    },
                    spreadRadius: {
                        content: 'number',
                        default: defaultFields.spreadRadius,
                        unit: 'px',
                    },
                    color: {
                        content: 'color',
                        default: defaultFields.color,
                    },
                }
            },
        }
    },
    createPartialCSS(prefix = '') {
        return `
            box-shadow: {${prefix}boxShadow.horizontalOffset} {${prefix}boxShadow.verticalOffset} {${prefix}boxShadow.blurRadius} {${prefix}boxShadow.spreadRadius} {${prefix}boxShadow.color};
        `
    }
}
