import LucidImage from '@/src/utils/LucidImage'

interface StaticMapProps {
  mapImage?: string
  size: 'small' | 'large'
}

const StaticMap = ({mapImage, size}: StaticMapProps) => {
  const [width, height] = size === 'large' ? [540, 540] : [250, 150]

  const sizes = `(max-width: ${width}) 100vw, ${width}px`

  return <LucidImage
    src={mapImage ?? ''}
    alt='Location Map'
    width={width}
    height={height}
    sizes={sizes}
  />
}

export default StaticMap
