import React from 'react'
import {parse} from 'node-html-parser'

export function renderIncludesAsReact(includesString, cms = false) {
    let elements = []
    if (!includesString) {
        return null
    }
    if (typeof window !== 'undefined' && cms) {
        const parser = new DOMParser()
        const doc = parser.parseFromString(includesString, 'text/html')
        elements = doc.head.children
    } else {
        const root = parse(includesString)
        elements = Array.from(root.querySelectorAll('*'))
    }
    if (typeof window !== 'undefined' && includesString) {
        return Array.from(elements).map((element, index) => {
            const {tagName, ...attributes} = element
            if (tagName.toLowerCase() === 'script') {
                if (cms) {
                    return null
                } else {
                    return (
                        <script
                            key={index}
                            dangerouslySetInnerHTML={{
                                __html: `try {${element.innerHTML}} catch (e) {console.error('Imported Script Error:', e)}`
                            }}
                        />
                    )
                }
            }
            return React.createElement(tagName.toLowerCase(), {
                key: index,
                ...attributes,
                dangerouslySetInnerHTML: {__html: element.innerHTML}
            })
        })
    }
    return null
}
