import {windowSize, WindowSizeKey} from '@/components/sections/shared/useWindowWidthListener'

export const styleCore = {
  config: {
    form: {
      content: {
        formAlignment: {
          content: 'select',
          default: 'column',
          description: 'The alignment of the form',
          options: [
            {
              label: 'Left',
              value: 'row'
            },
            {
              label: 'Center',
              value: 'column'
            },
            {
              label: 'Right',
              value: 'row-reverse'
            }
          ]
        },
        formAlignmentMobile: {
          content: 'select',
          description: 'The mobile alignment of the form',
          default: 'column',
          options: [
            {
              label: 'Left',
              value: 'row'
            },
            {
              label: 'Center',
              value: 'column'
            },
            {
              label: 'Right',
              value: 'row-reverse'
            }
          ],
        },
      },
    }
  },
  target: {
    componentName: 'contactform',
  },
  css: `
    {target} .flexbox-alignment {
      align-items: center;
      flex-direction: {form.formAlignment};
    }
    
    @media (max-width: ${windowSize[WindowSizeKey.md]}px) {
      {target} .flexbox-alignment {
        flex-direction: {form.formAlignmentMobile};
      }
    }
  `
}
