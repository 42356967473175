export async function fetcher(endpoint, options = {}) {
  const {body = {}, headers = {}} = options

  const fetchOptions = {
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    method: 'POST',
    body: JSON.stringify(body),
  }

  const res = await fetch(endpoint, fetchOptions)

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  // https://swr.vercel.app/docs/error-handling#status-code-and-error-object
  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.')
    // Attach extra info to the error object.
    error.info = await res.json()
    error.status = res.status
    throw error
  }

  return await res.json()
}

export function serverSideLucidFetcher(query, variables = {}) {
  const endpoint = process.env.NEXT_PUBLIC_LUCID_API_ENDPOINT
  const apiKey = process.env.LUCID_API_KEY

  return fetch(endpoint, {
    headers: {
      'Content-Type': 'application/json',
      'X-API-KEY': apiKey,
    },
    method: 'POST',
    body: JSON.stringify({
      query,
      variables,
    }),
  })
}

/**
 *
 * @template T | any
 * @param query: string
 * @param variables?: {[key: string]: any}
 * @returns {Promise<{data: {[resolver: string]: T}, errors?: Record<string, any>[]}>}
 */
export async function lucidDataFetcherV2(query, variables = {}) {
  const body = {
    query,
    variables,
  }
  const raw = await fetch('/api/graphql-auth', {
    method: 'POST',
    body: JSON.stringify(body),
  })
  const data = await raw.json()
  if (data.error) throw new Error(data.error)
  return data
}
