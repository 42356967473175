import {Duration, VideoObject} from 'schema-dts'
import {jsonLdScriptProps} from 'react-schemaorg'
import Head from 'next/head'

type Props = {
  name: string
  description: string
  thumbnailUrl: string
  uploadDate: string
  duration: Duration
  contentUrl: string
  embedUrl?: string
}

export default function SchemaVideo({
  name,
  description,
  thumbnailUrl,
  uploadDate,
  duration,
  contentUrl,
  embedUrl,
}: Props) {
  return (
    <Head>
      <script
        {...jsonLdScriptProps<VideoObject>({
          '@context': 'https://schema.org',
          '@type': 'VideoObject',
          name: name,
          description: description,
          thumbnailUrl: thumbnailUrl,
          uploadDate: uploadDate,
          duration: duration,
          contentUrl: contentUrl,
          embedUrl: embedUrl,
        })}
      />
    </Head>
  )
}
