import {lucidDataFetcherV2} from '@/src/graphql/fetchers'
import {GET_STATIC_MAP} from '@/src/graphql/queries'

type UploadMapProps = {
  site_id: string
  center?: {
    lat: number
    long: number
  }
  address: string
}

const uploadMapImage = async ({site_id, center, address}: UploadMapProps) => {
  const zoom = 15
  const width = 540
  const height = 540

  const centerParam = center ? `${center.lat},${center.long}` : encodeURIComponent(address)

  try {
    const response = await lucidDataFetcherV2<{data: {getStaticMap: string}}>(GET_STATIC_MAP, {
      site_id,
      center: centerParam,
      zoom,
      width,
      height,
    })
  
    return response.data.getStaticMap
  } catch (error) {
    console.error('Error uploading map image:', error)
  }
}

export default uploadMapImage
