import Helpers from '@/src/utils/shared/helpers'
import {Location} from './LocationPicker'
import {useLucidContext} from '@/src/state/ServerSideStore'

type prepareMapPropertiesProps = {
  customCoordinates: string
  customMapUrl?: string
}

export const useLocationData = (locationId: string, cms: boolean) => {
  const [{site_contents}] = useLucidContext(cms)
  const locations = mapLocations((site_contents?.practice as {[key: string]: any})?.locations ?? [])
  const location = getLocationById(locationId, locations)

  const schemaProps = {
    name: location?.name || 'Default Name',
    image: location?.image || 'Default Image URL',
    streetAddress: location?.address || 'Default Address',
    addressLocality: location?.city || 'Default City',
    addressRegion: location?.state || 'Default State',
    postalCode: location?.postal_code || 'Default Postal Code',
    addressCountry: location?.country || 'Default Country',
    telephone: location?.phone || 'Default Phone',
    openingHours: location?.hours || 'Default Opening Hours'
  }

  return {location, schemaProps}
}

export const prepareMapProperties = (data: prepareMapPropertiesProps, location: Location) => {
  const customMapUrl = data.customMapUrl
  const mapLink = customMapUrl || createGoogleMapsLink(location)

  const hasCustomCoordinates = data.customCoordinates && data.customCoordinates.includes(',')
  const [latitude, longitude] = hasCustomCoordinates ? data.customCoordinates.split(',').map((coord: string) => coord.trim()) : [null, null]
  const mapCenter = latitude && longitude ? {lat: parseFloat(latitude), long: parseFloat(longitude)} : undefined
  const mapAddress = hasCustomCoordinates ? undefined : `${location?.address} ${location?.address2 ?? ''}, ${location?.city}, ${location?.state} ${location?.postal_code ?? ''} ${location?.country ?? ''}`
  return {mapLink, mapCenter, mapAddress}
}

export function getLocationById(id: string, locations: Location[]) {
  return locations.find((location: any) => location.id === id)
}

export function mapLocations(locations: Location[]) {
  return Object.keys(locations).map((key: string) => {
    return {
      id: key,
      ...(locations as {[key: string]: any})[key],
    }
  })
}

export function createGoogleMapsLink(location: Location) {
  if (!location) return
  const fullAddress = `${location.address} ${location.address2 ?? ''}, ${location.city}, ${location.state} ${location.postal_code ?? ''} ${location.country ?? ''}`
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fullAddress)}`
}

const DayHours = ({day, hoursRange}: {day: string, hoursRange: string}) => {
  if (!hoursRange) {
    return (
      <div className="hours-line">
        <span className="day">{day}</span>
        <span className="hours">Closed</span>
      </div>
    )
  }

  const [start, end] = hoursRange.split(' - ')
  return (
    <div className="hours-line">
      <span className="day">{day}</span>
      <span className="hours">
        {Helpers.format.human.time.convertTo12HourFormat(start)} - {Helpers.format.human.time.convertTo12HourFormat(end)}
      </span>
    </div>
  )
}

export const HoursDisplay = ({hoursData}: {hoursData: string}) => {
  const daysofWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  const hoursByDay: {[key: string]: string} = {}

  if (hoursData) {
    hoursData.split('\n').forEach(line => {
      const [day, hours] = line.split(': ')
      hoursByDay[day] = hours
    })
  }

  return (
    <div className="hours">
      {daysofWeek.map(day => (
        <DayHours key={day} day={day} hoursRange={hoursByDay[day]} />
      ))}
    </div>
  )
}
