type TokenTypes = 'selector' | 'comment' | 'rule' | 'media query'

const EOF = '___EOF___' as const
const EMPTY = '___NONE___' as const
const COMMENT_START = '/*' as const
const COMMENT_END = '*/' as const
const TOKEN_MEDIA_OPENER = '@media' as const
const BLOCK_OPENER = '{' as const
const BLOCK_CLOSER = '}' as const
const RULE_CLOSER = ';' as const
const RULE_DELIMITER = ':' as const
const TOKEN_DELIMITERS = [RULE_CLOSER, BLOCK_OPENER, BLOCK_CLOSER] as string[]
const NL = ['\n', '\r', '\r\n']
const NOOP = [' ', '\t']

export default class CssParser {

  private index = 0

  private currentLine = 0

  private leftChar: string[number] = EMPTY
  private centerChar: string[number]
  private rightChar: string[number] = EMPTY

  private readonly css: string

  private tokens: Token[] = []
  constructor(css: string) {
    this.css = css
    this.centerChar = this.css[this.index]
    this.collectTokens()
  }

  getTokens() {
    return this.tokens
  }

  tokensWalk(processor: (token: Token) => void) {
    const recursiveIterator = (tokens: Token[]) => {
      for (const token of tokens) {
        processor(token)
        if (token.children) {
          recursiveIterator(token.children)
        }
      }
    }
    recursiveIterator(this.tokens)
  }

  minify() {
    let output = ''
    for (const token of this.tokens) {
      output += token.getCssOutput(true)
    }
    return output
  }

  /**
   * TODO: Implement method
   */
  getPretty() {
    throw new Error('Not Implemented')
  }

  isNoop() {
    return NOOP.indexOf(this.centerChar) > -1
  }

  isNl() {
    return NL.indexOf(this.centerChar) > -1
  }

  isDelimiter() {
    return TOKEN_DELIMITERS.indexOf(this.centerChar) > -1
  }

  getWarnings() {
    return this.generateCssWarnings(this.tokens)
  }

  private collectTokens() {
    while (this.centerChar !== EOF) {
      this.consumeEmptiness()

      if (this.centerChar + this.rightChar === COMMENT_START) {
        this.tokens.push(this.collectComment())
        continue
      }
      const token = this.getNextCssToken()
      if (token) {
        this.tokens.push(token)
      }
      this.consume()
    }
  }

  private generateCssWarnings(tokens: Token[]): string[] {
    let warnings: string[] = []
    for (const token of tokens) {
      if (!token.isValid) {
        warnings.push(`Your CSS has an invalid ${token.type} at or around line ${token.lineNumber}`)
      }
      if (token.children && token.children.length > 0) {
        warnings = warnings.concat(this.generateCssWarnings(token.children))
      }
    }
    return warnings
  }

  private collectComment(): Token {
    let buffer = ''
    //consume Start of comment (two character)
    this.consume()
    this.consume()
    while (this.centerChar !== EOF) {
      const commentEnd = this.centerChar + this.rightChar === COMMENT_END
      buffer += commentEnd ? '' : this.centerChar
      this.consume()
      if (commentEnd) {
        // consume comment end char
        this.consume()
        break
      }
    }
    return new Token('comment', this.currentLine, [buffer])
  }

  private getNextCssToken(): Token | undefined {
    const buffer = this.getInitialBlockBuffer()
    switch (this.centerChar) {
      case BLOCK_OPENER:
        this.consume()
        return this.getBlockToken(buffer)
      default:
        this.consume()
    }
  }

  private getBlockToken(buffer: string): Token {
    this.consumeEmptiness()
    if (buffer.startsWith(TOKEN_MEDIA_OPENER)) {
      return this.getMediaQueryToken(buffer)
    }
    const lineNumber = this.currentLine
    const selectors = buffer.split(',')
    const blockTokens: Token[] = []

    while (this.centerChar !== BLOCK_CLOSER && this.centerChar !== EOF) {
      if (this.isNl()) {
        this.consume()
        continue
      }
      const token = this.getRuleToken()
      if (token) {
        blockTokens.push(token)
        this.consume()
      }
    }
    return new Token('selector', lineNumber, selectors, blockTokens)
  }

  private getRuleToken(): Token | undefined {
    let buffer = ''

    if (this.centerChar + this.rightChar === COMMENT_START) {
      return this.collectComment()
    }
    while (this.centerChar !== RULE_CLOSER && this.centerChar !== BLOCK_CLOSER && this.centerChar !== EOF) {
      buffer += this.centerChar
      this.consume()
    }

    if (this.centerChar === BLOCK_CLOSER || this.centerChar === EOF) return

    const [left, right] = this.getRuleParts(buffer)
    return new Token('rule', this.currentLine, [`${left}${RULE_DELIMITER} ${right}${RULE_CLOSER}`])
  }

  private getRuleParts(buffer: string): [string, string] {
    let left = ''
    let index = 0
    while (buffer[index] !== RULE_DELIMITER) {
      left += buffer[index]
      index += 1
    }
    const right = buffer.substring(left.length + 1).trimStart()

    return [left.trimStart(), right.trimEnd()]
  }

  private getMediaQueryToken(mediaBuffer: string): Token {
    const line = this.currentLine
    const mediaTokens: Token[] = []
    while (this.centerChar !== BLOCK_CLOSER && this.centerChar) {
      const token = this.getNextCssToken()
      if (token) {
        mediaTokens.push(token)
        this.consume()
      } else {
        break
      }
    }
    return new Token('media query', line, [mediaBuffer], mediaTokens)
  }

  private getInitialBlockBuffer(): string {
    let buffer = ''
    while (this.centerChar !== EOF && !this.isDelimiter()) {
      if (this.isNoop() || this.isNl()) {
        this.consume()
        continue
      }
      buffer += this.centerChar
      this.consume()
    }
    return buffer
  }

  private consume() {
    this.index += 1
    this.leftChar = this.centerChar
    if (this.index >= this.css.length) {
      this.centerChar = EOF
      this.rightChar = EMPTY
    } else {
      this.centerChar = this.css[this.index]
      this.rightChar = this.css[this.index + 1]
      if (this.isNl()) {
        this.currentLine += 1
      }
    }
  }

  private consumeEmptiness() {
    while (this.isNoop() || this.isNl()) {
      this.consume()
    }
  }
}

export class Token {
  type: TokenTypes
  content: string[]
  lineNumber: number
  isValid: boolean
  children: Token[] | null

  constructor(type: TokenTypes, lineNumber: number, content: string[], children?: Token[]) {
    this.type = type
    this.content = content
    this.lineNumber = lineNumber
    this.children = children ?? null
    this.isValid = content.reduce((previousValue, currentValue) => {
      return previousValue && currentValue !== ''
    }, true) && (this.children === null || this.children.length > 0)
  }

  /**
   * TODO: Implement pretty print
   */
  getCssOutput(skipComments = true): string {
    switch (this.type) {
      case 'comment':
        if (skipComments) return ''
        return `/*${this.content.join('\n')}*/`
      case 'selector':
        let selectorOutput = this.content.join(',')
        if (!this.children) return BLOCK_OPENER + BLOCK_CLOSER
        selectorOutput += BLOCK_OPENER
        for (const child of this.children) {
          selectorOutput += child.getCssOutput()
        }
        selectorOutput += BLOCK_CLOSER
        return selectorOutput
      case 'media query':
        let mediaOutput = this.content.join('')
        if (!this.children) return BLOCK_OPENER + BLOCK_CLOSER
        mediaOutput += BLOCK_OPENER
        for (const child of this.children) {
          mediaOutput += child.getCssOutput()
        }
        mediaOutput += BLOCK_CLOSER
        return mediaOutput
      case 'rule':
        return this.content.join('')
    }
  }
}
