import styled from 'styled-components'
import SectionText from './SectionText'

const StyledHeading = styled.div`
  h2 {
    text-align: center;
  }
  .title {
    font-size: 3rem;
    display: block;
  }
  .subtitle {
    font-size: 2rem;
    display: block;
  }
`

// const H2 = styled.h2`
//   color: ${(props) => props.style?.color || '#222222'};
//   font-size: ${(props) => props.style?.fontSize || '2.5rem'};
//   font-family: ${(props) => props.style?.fontFamily || 'Arial'};
//   font-weight: ${(props) => props.style?.fontWeight || '400'};
//   line-height: ${(props) => props.style?.lineHeight || '1.25'};
//   span:last-child {
//     display: block;
//     /* TODO: get rid of em and use props for subtitle span */
//     font-size: 0.75em;
//   }
// `

interface SectionHeadingProps {
  title?: string
  subtitle?: string
  cms: boolean
}

function SectionHeading({title, subtitle, cms}: SectionHeadingProps) {
  if (!title && !subtitle) {
    return null
  }
  // TODO: TODO: Check for H1?
  // TODO: Clarify what needs to be an h1/h2/h3/span
  return (
    <StyledHeading>
      {title || subtitle ? (
        <h2>
          {title ? (
            <span className="title heading-1">
              <SectionText name="title" text={title} cms={cms} />
            </span>
          ) : null}
          {subtitle ? (
            <span className="subtitle heading-3">
              <SectionText name="subtitle" text={subtitle} cms={cms} />
            </span>
          ) : null}
        </h2>
      ) : null}
    </StyledHeading>
  )
}

export default SectionHeading
