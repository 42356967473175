import {InlineTextWithVariables} from './SectionsWithVariables'

export interface SectionTextProps {
  name: string
  text: string
  cms: boolean
}

export default function SectionText({
  name,
  text,
  cms,
}: SectionTextProps) {
  if (!text) {
    return null
  }
  return <InlineTextWithVariables cms={cms} name={name} focusRing={{borderRadius: 4}} value={text} />
}
